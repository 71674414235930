import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { productsPerPage } from "@app/constants";
import { FilterQuerySet } from "@utils/collections";
import {
  DeliveryFilterType,
  LocationFilterType,
  PriceFilterType,
} from "@utils/filterData";
import {
  filtersChangeHandler,
  getDeliveryFilter,
  getFilters,
  getLocationFilter,
  getPricesFilter,
} from "@utils/filters";

interface FilterType {
  [key: string]: string[];
}
interface ProductFilterInputType {
  presetAttributeFilter?: FilterType;
  presetPriceFilters?: PriceFilterType;
  presetLocationFilter?: LocationFilterType[];
  presetDeliveryFilter?: DeliveryFilterType;
  categoryFilter?: FilterType;
}

const useProductFiltering = ({
  presetAttributeFilter,
  presetPriceFilters,
  presetLocationFilter,
  presetDeliveryFilter,
  categoryFilter,
}: ProductFilterInputType = {}) => {
  const router = useRouter();

  const [sort, setSort] = useQueryParam("sortBy", StringParam);

  const [queryParams, setQueryParams] = useQueryParam(
    "filters",
    FilterQuerySet
  );

  const [keyword, setKeyword] = useQueryParam("keyword", StringParam);

  const [clientFiltered, setClientFiltered] = useState(false);

  const [pricesFilter, setPricesFilter] = useState<PriceFilterType[]>(
    presetPriceFilters?.gte || presetPriceFilters?.lte
      ? [presetPriceFilters]
      : getPricesFilter(queryParams)
  );

  const [locationFilter, setLocationFilter] = useState<LocationFilterType[]>(
    presetLocationFilter?.length
      ? presetLocationFilter
      : getLocationFilter(queryParams)
  );

  const [deliveryFilter, setDeliveryFilter] = useState<DeliveryFilterType>(
    presetDeliveryFilter || getDeliveryFilter(queryParams)
  );

  useEffect(() => {
    if (
      !presetLocationFilter?.length &&
      JSON.stringify(locationFilter) !==
        JSON.stringify(getLocationFilter(queryParams))
    ) {
      setLocationFilter(getLocationFilter(queryParams));
    }
    if (
      !presetPriceFilters &&
      JSON.stringify(pricesFilter) !==
        JSON.stringify(getPricesFilter(queryParams))
    ) {
      setPricesFilter(getPricesFilter(queryParams));
    }
    if (queryParams?.prices?.length === 0) {
      const updatedParams = { ...queryParams };
      delete updatedParams.prices;
      setQueryParams(updatedParams);
    }
  }, [presetLocationFilter, presetPriceFilters]);

  useEffect(() => {
    if (clientFiltered) {
      setClientFiltered(false);
    }
  }, [clientFiltered]);

  useEffect(() => {
    setClientFiltered(true);
  }, [deliveryFilter]);

  useEffect(() => {
    if (clientFiltered)
      setQueryParams((prev) => {
        const updatedParams = { ...prev };

        if (deliveryFilter?.postCode) {
          updatedParams["post-code"] = [deliveryFilter.postCode];
        } else {
          delete updatedParams["post-code"];
        }

        if (deliveryFilter?.date) {
          updatedParams.date = [deliveryFilter.date];
        } else {
          delete updatedParams.date;
        }

        return updatedParams;
      });
  }, [deliveryFilter]);

  const filters = getFilters(
    productsPerPage,
    pricesFilter,
    locationFilter,
    queryParams,
    sort,
    deliveryFilter || null,
    keyword
  );

  if (presetAttributeFilter) {
    filters.attributes = { ...filters.attributes, ...presetAttributeFilter };
  }

  if (categoryFilter) {
    filters.categorySlugs = categoryFilter.category;
  }

  const handleClearFilters = () => setQueryParams({});

  const handleFiltersChange = (name, value, singleValue) => {
    setClientFiltered(true);
    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )(name, value, singleValue);
  };

  const handlePricesFilter = (pricesFilterValue: PriceFilterType[]) => {
    setClientFiltered(true);
    const priceSlugs = pricesFilterValue.map((filter) => filter.slug || "");

    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )("prices", priceSlugs.join("_"), true);

    setPricesFilter(pricesFilterValue);
  };

  const handleLocationFilter = (locationFilterValue: LocationFilterType) => {
    setClientFiltered(true);
    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )("location", String(locationFilterValue.slug));

    const newLocationFilter: LocationFilterType[] = [...locationFilter];

    const existingIndex = newLocationFilter.indexOf(locationFilterValue);
    if (existingIndex > -1) {
      newLocationFilter.splice(existingIndex);
    } else {
      newLocationFilter.push(locationFilterValue);
    }

    setLocationFilter(newLocationFilter);
  };

  const handleDeliveryFilter = (deliveryFilterValue: DeliveryFilterType) => {
    setClientFiltered(true);

    const { postCode, date } = deliveryFilterValue;

    if (postCode) {
      filtersChangeHandler(
        { attributes: queryParams },
        queryParams,
        setQueryParams
      )("post-code", postCode, true);
    }

    if (date) {
      filtersChangeHandler(
        { attributes: queryParams },
        queryParams,
        setQueryParams
      )("date", date, true);
    }

    setDeliveryFilter(deliveryFilterValue);
  };

  const handleOrderChange = (value: { value: string | null }) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (value?.value) {
      searchParams.set("sortBy", value.value);
    } else {
      searchParams.delete("sortBy");
    }

    if (!searchParams.has("filters")) {
      searchParams.set("filters", "rental-period_4-days");
    }

    router.replace(
      { pathname: router.pathname, search: searchParams.toString() },
      undefined,
      { shallow: true }
    );

    setSort(value?.value || null);
  };

  const hasAttributeFilter = Object.keys(filters?.attributes || {}).length > 0;
  const hasDeliveryFilter = !deliveryFilter?.date && !deliveryFilter?.postCode;
  const hasPriceFilter = pricesFilter;

  const isFiltered = hasAttributeFilter || hasDeliveryFilter || hasPriceFilter;

  return {
    clientFiltered,
    filters,
    pricesFilter,
    locationFilter,
    deliveryFilter,
    hasDeliveryFilter,
    handleClearFilters,
    handleFiltersChange,
    handlePricesFilter,
    handleLocationFilter,
    handleDeliveryFilter,
    setDeliveryFilter,
    isFiltered,
    handleOrderChange,
    keyword,
    setKeyword,
  };
};

export default useProductFiltering;
